import React from 'react';

import { CmsCtaType } from '../../cms/types';
import { Button } from '../../../design-system';
import { ColorButton } from '../../common/components/Custom';

type Props = {
  id: string;
  text: string;
  type: CmsCtaType;
  color?: string;
  fontColor?: string;
  onClick?: () => void;
};

export const BannerCta = ({ id, text, type, color, fontColor, onClick }: Props) =>
  type === 'black button' ? (
    <Button id={id} fontWeight="600" onClick={onClick}>
      {text}
    </Button>
  ) : type === 'white button' ? (
    <Button id={id} preset="primaryLight" fontWeight="600" onClick={onClick}>
      {text}
    </Button>
  ) : type === 'ghost button' && text !== '' ? (
    <Button id={id} preset="subtle" fontWeight="600" onClick={onClick}>
      {text}
    </Button>
  ) : type === 'color button' ? (
    <ColorButton id={id} cmsColor={color} fontColor={fontColor} fontWeight="600" onClick={onClick}>
      {text}
    </ColorButton>
  ) : null;
