import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from 'aws-amplify/auth';
import { datadogLogs } from '@datadog/browser-logs';

import { DynamicButtonStatus } from '../../design-system';
import types from './actionTypes';
import { getIdPayload, getSocialUserName } from './utils';
import {
  ERR_USER_EXISTS,
  ERR_PARAMS,
  ERR_AUTH,
  MSG_ONGOING_LOGIN,
  ERR_ADDRESS_NOT_FOUND,
  ERR_PASSWORD,
} from './locale';
import { ParamsSignUp, ParamsLogin, ErrorCodes, SocialProviders } from './types';
import { mergeCart } from '../cart/actions';
import { setCtaState, handleDynamicButton, setFeedback, setFormValidation } from '../form/actions';
import { Forms } from '../form/types';
import { pushToGTM } from '../tracking';
import { Events } from '../tracking/types';
import { ERR_GENERIC } from '../common/locale';
import { getBFFData, Queries } from '../api';

export const requestLogin = () => action(types.REQUEST_LOGIN, null);
export const errorLogin = (payload: string) => action(types.ERROR_LOGIN, payload);
export const successLogin = (payload) => action(types.SUCCESS_LOGIN, payload);
export const successLogout = () => action(types.SUCCESS_LOGOUT, null);
export const requestSignup = () => action(types.REQUEST_SIGNUP, null);
export const errorSignup = (payload: string) => action(types.ERROR_SIGNUP, payload);
export const successSignup = (payload) => action(types.SUCCESS_SIGNUP, payload);
export const errorSocialLogin = (payload) => action(types.ERROR_SOCIAL, payload);
export const checkUserExist = (payload: boolean | null) => action(types.SET_USER_EXIST, payload);
export const setHasForgotPassword = (payload) => action(types.SET_HAS_FORGOT_PASSWORD, payload);

type LoginActions = ActionType<typeof requestLogin | typeof successLogin | typeof errorLogin>;
type LogoutActions = ActionType<typeof successLogout>;
type SignupActions = ActionType<typeof requestSignup | typeof successSignup | typeof errorSignup>;
type SocialActions = ActionType<typeof errorSocialLogin>;
type UserExistActions = ActionType<typeof checkUserExist>;
type ForgotPasswordActions = ActionType<typeof setHasForgotPassword>;

export function logout() {
  return async (dispatch: Dispatch<LogoutActions>) => {
    await signOut();
    window.FB?.getLoginStatus((response) => {
      if (response.status === 'connected') {
        window.FB?.logout();
      }
    });
    dispatch(successLogout());
  };
}

export function checkEmail(email: string | null) {
  return async (dispatch) => {
    if (!email) {
      dispatch(checkUserExist(null));
      return;
    }
    dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Loading }));
    dispatch(requestLogin());
    try {
      const response = await getBFFData(Queries.getIsRegistered, { email });
      if (response && response.ok === false) {
        dispatch(errorLogin(ERR_AUTH));
        const err = new Error();
        err.name = 'Login Error';
        err.message = response.data;
        datadogLogs.logger.error(err.name, err);
      }
      const isRegistered = response.data.isRegistered.registered;
      dispatch(checkUserExist(isRegistered));
    } catch (error) {
      dispatch(errorLogin(ERR_AUTH));
      const err = new Error();
      err.name = 'Login Error';
      err.message = error.code;
      datadogLogs.logger.error(err.name, err);
    }
    dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Default }));
  };
}

export function login({ email, password }: ParamsLogin) {
  return async (dispatch) => {
    dispatch(setCtaState({ form: Forms.signInPassword, ctaState: DynamicButtonStatus.Loading }));
    dispatch(requestLogin());
    try {
      await signIn({ username: email, password });
      const userAttributes = await fetchUserAttributes();
      await dispatch(mergeCart());
      dispatch(successLogin(userAttributes));
      trackLogin('login', userAttributes?.sub ?? '');
    } catch (error) {
      if (error.code && error.code === ErrorCodes.NotAuthorizedException) {
        dispatch(
          setFormValidation({ form: Forms.signInPassword, values: { password: ERR_PASSWORD } })
        );
      }

      if (
        error.code !== ErrorCodes.NotAuthorizedException &&
        error.code !== ErrorCodes.UserNotFoundException
      ) {
        dispatch(errorLogin(ERR_AUTH));
        const err = new Error();
        err.name = 'Login Error';
        err.message = error.code;
        datadogLogs.logger.error(err.name, err);
      }
    }
    dispatch(setCtaState({ form: Forms.signInPassword, ctaState: DynamicButtonStatus.Default }));
  };
}

export function signup({ email, password, firstName, lastName, optInEmail }: ParamsSignUp) {
  return async (dispatch) => {
    dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Loading }));
    dispatch(requestSignup());
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            website: optInEmail ? '1' : '',
          },
        },
      });
      await signIn({ username: email, password });
      const userAttributes = await fetchUserAttributes();
      await dispatch(mergeCart());
      dispatch(successSignup(userAttributes));
      trackLogin('signin', userAttributes?.sub ?? '');
    } catch (error) {
      const errMsg =
        error.code === ErrorCodes.UsernameExistsException
          ? ERR_USER_EXISTS
          : error.code === ErrorCodes.InvalidParameterException
            ? ERR_PARAMS
            : error.code === ErrorCodes.UserLambdaValidationException
              ? ERR_ADDRESS_NOT_FOUND
              : ERR_AUTH;
      dispatch(errorSignup(errMsg));

      if (
        error.code !== ErrorCodes.UsernameExistsException &&
        error.code !== ErrorCodes.InvalidParameterException &&
        error.code !== ErrorCodes.UserLambdaValidationException
      ) {
        const err = new Error();
        err.name = 'Signup Error';
        err.message = error.code;
        datadogLogs.logger.error(err.name, err);
      }
    }
    dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Default }));
  };
}

export function relogin() {
  return async (dispatch: Dispatch<LoginActions>) => {
    const idPayload = await getIdPayload();
    if (idPayload) {
      dispatch(successLogin(idPayload));
    }
  };
}

export function requestPasswordReset(email: string) {
  return async (dispatch) => {
    dispatch(handleDynamicButton(Forms.forgotPassword, resetPassword, [{ username: email }]));
  };
}

export type SubmitPasswordResetParams = {
  email: string;
  password: string;
  token: string;
};

export function submitPasswordReset({ email, password, token }: SubmitPasswordResetParams) {
  return async (dispatch) => {
    dispatch(
      handleDynamicButton(Forms.forgotPassword, confirmResetPassword, [
        {
          username: email,
          newPassword: password,
          confirmationCode: token,
        },
      ])
    );
  };
}

export function requestNewPasswordReset(email: string) {
  return async (dispatch: Dispatch<ActionType<typeof setFeedback>>) => {
    try {
      resetPassword({ username: email });
      dispatch(
        setFeedback({
          form: Forms.forgotPassword,
          ok: true,
          message: ErrorCodes.ExpiredCodeException,
        })
      );
    } catch (error) {
      dispatch(
        setFeedback({
          form: Forms.forgotPassword,
          ok: false,
          message: ErrorCodes.ExpiredCodeException,
        })
      );
    }
  };
}

export function socialLogin({
  provider,
  credential,
}: {
  provider: SocialProviders;
  credential?: string;
}) {
  return async (dispatch) => {
    dispatch(setFeedback({ form: Forms.socialLogin, message: MSG_ONGOING_LOGIN, ok: true }));
    dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Loading }));
    dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Loading }));
    dispatch(requestLogin());
    try {
      const { username, accessToken } = await getSocialUserName({ provider, credential });

      await signIn({
        username,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP',
        },
      });

      const { identityId } = await fetchAuthSession();
      if (!identityId) {
        dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Default }));
        dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Default }));
        throw 'Failed to get the Identity Id';
      }

      await confirmSignIn({
        challengeResponse: `${identityId}|${provider}|${accessToken}`,
      });
      const userAttributes = await fetchUserAttributes();
      if (!userAttributes?.username && !userAttributes?.email) {
        dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Default }));
        dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Default }));
        throw 'Bad challenge response';
      }

      await dispatch(mergeCart());
      dispatch(successLogin(userAttributes));
      trackLogin('login', userAttributes?.sub ?? '');
      dispatch(setFeedback({ form: Forms.socialLogin, message: '', ok: false }));
      dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Success }));
      dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Success }));
    } catch (error) {
      dispatch(errorSocialLogin(typeof error === 'string' ? error : JSON.stringify(error)));
      dispatch(setFeedback({ form: Forms.socialLogin, message: ERR_GENERIC, ok: false }));
      dispatch(setCtaState({ form: Forms.signInEmail, ctaState: DynamicButtonStatus.Default }));
      dispatch(setCtaState({ form: Forms.signUp, ctaState: DynamicButtonStatus.Default }));
    }
  };
}

function trackLogin(eventType: string, id: string) {
  if (id) {
    pushToGTM(Events.updateAuthStatus, {
      eventType: eventType,
      user: {
        id,
      },
    });
  }
}

export const setRedirectTo = (payload: string) => action(types.SET_REDIRECT_TO, payload);

type RedirectActions = ActionType<typeof setRedirectTo>;

export type AuthActions =
  | LoginActions
  | LogoutActions
  | SignupActions
  | SocialActions
  | RedirectActions
  | UserExistActions
  | ForgotPasswordActions;
