import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { MobileMenuItem } from './MobileMenuItem';
import { colors } from '../../../design-system';
import { MobileMenuTreeItem } from '../types';
import paths from '../../routing/paths';
import { MiniWishlist } from '../../wishlist';
import { transformMenuItemsForMobile } from '../utils';

type Props = {
  items: MobileMenuTreeItem[];
  onMenuClick?: (subMenu: MobileMenuTreeItem[], name: string) => void;
  onClickClose: () => void;
  isFadingIn?: boolean;
  isFadingOut?: boolean;
};

const StyledMobileMenuItemList = styled.nav`
  transition: opacity 300ms ease-out;
  &.fadeOut {
    opacity: 0;
  }
  &.fadeIn {
    opacity: 1;
  }
  .mobile-menu-list__container {
    padding: 16px 0;
    overflow-x: hidden;
    border-top: 1px solid ${colors.LIGHT};
    margin: 0;
    list-style-type: none;
  }
`;

const menuItemsAccountActions: MobileMenuTreeItem[] = [
  {
    name: 'Suivre mon colis',
    picto_for_mobile: 'box',
    link: paths.ORDER_TRACKING,
    level: 1,
    subLevel: [],
  },
];

const menuItemsSupport: MobileMenuTreeItem[] = [
  {
    name: 'Boutiques',
    picto_for_mobile: 'boutiques',
    link: paths.STORE_LOCATOR,
    level: 1,
    subLevel: [],
  },
  { name: 'Contact', picto_for_mobile: 'mail', link: paths.CONTACT, level: 1, subLevel: [] },
  { name: 'Aide', picto_for_mobile: 'chat', link: paths.GENERIC[1], level: 1, subLevel: [] },
];

export const MobileMenuItemList = ({
  items,
  onMenuClick,
  isFadingIn,
  isFadingOut,
  onClickClose,
}: Props) => {
  const [currentItems, setCurrentItems] = useState(items);

  useEffect(() => {
    setCurrentItems(transformMenuItemsForMobile(items));
  }, [items]);

  return (
    <StyledMobileMenuItemList className={`${isFadingOut ? 'fadeOut' : isFadingIn ? 'fadeIn' : ''}`}>
      <ul className={'mobile-menu-list__container'}>
        {currentItems.map((item) => (
          <li key={`mobile-item-list-${item.name}`}>
            <MobileMenuItem
              name={item.name}
              picto_for_mobile={item.picto_for_mobile}
              level={item.level}
              link={item.link}
              subLevel={item.subLevel}
              onMenuClick={
                onMenuClick ? () => onMenuClick(item.subLevel || [], item.name) : undefined
              }
              haveSubMenu={Boolean(item.subLevel?.length)}
              isPrimaryLink={item.isPrimaryLink}
              onClickClose={onClickClose}
            />
          </li>
        ))}
      </ul>
      {currentItems[0]?.level === 1 && (
        <>
          <ul className={'mobile-menu-list__container'}>
            <MiniWishlist />
            {menuItemsAccountActions.map((item) => (
              <li key={`mobile-item-list-${item.name}`}>
                <MobileMenuItem
                  name={item.name}
                  picto_for_mobile={item.picto_for_mobile}
                  level={item.level}
                  link={item.link}
                  subLevel={item.subLevel}
                  onMenuClick={
                    onMenuClick ? () => onMenuClick(item.subLevel || [], item.name) : undefined
                  }
                  haveSubMenu={Boolean(item.subLevel?.length)}
                  isPrimaryLink={item.isPrimaryLink}
                  shouldBeInLowerCase
                  onClickClose={onClickClose}
                />
              </li>
            ))}
          </ul>
          <ul className={'mobile-menu-list__container'}>
            {menuItemsSupport.map((item) => (
              <li key={`mobile-item-list-${item.name}`}>
                <MobileMenuItem
                  name={item.name}
                  picto_for_mobile={item.picto_for_mobile}
                  level={item.level}
                  link={item.link}
                  subLevel={item.subLevel}
                  onMenuClick={
                    onMenuClick ? () => onMenuClick(item.subLevel || [], item.name) : undefined
                  }
                  haveSubMenu={Boolean(item.subLevel?.length)}
                  isPrimaryLink={item.isPrimaryLink}
                  shouldBeInLowerCase
                  onClickClose={onClickClose}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </StyledMobileMenuItemList>
  );
};
