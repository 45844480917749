import React from 'react';
import { Global, css } from '@emotion/core';

import { fontFamilyStyles } from './FontFamilyStyles';
import { rheostatStyles } from './RheostatStyles';
import { reactSlickStyles } from './ReactSlickStyles';
import { richTextStyles } from './RichTextStyles';
import { colors } from '../design-system';

export const GlobalStylesComponent = () => {
  return (
    <Global
      styles={css`
        html {
          scroll-behavior: smooth;
        }
        body:has(dialog[open]:modal) {
          overflow-y: hidden !important;
        }
        body:has(.sidebar.sidebar--is-open) {
          overflow-y: hidden !important;
        }
        .disable-pointer-events {
          & > * {
            pointer-events: none;
          }
        }
        .anchor-animated {
          * {
            margin: 0;
          }

          display: inline-block;
          position: relative;
          text-decoration: none;
          white-space: nowrap;

          :after {
            content: '';
            display: block;
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transform: scaleX(1);
            transform-origin: 0 100%;
            transition: transform 0.4s ease-in-out;
            pointer-events: none;
          }

          @media (hover: hover) {
            :hover {
              :after {
                transform: scaleX(0);
                transform-origin: 100% 0;
              }
            }
          }
        }

        button.anchor-animated {
          border: none;
          background: unset;
          padding: 0;
          color: ${colors.BLACK};

          @media (hover: hover) {
            :hover {
              cursor: pointer;
            }
          }
        }
        strong {
          font-weight: 700;
        }

        ${fontFamilyStyles}
        ${rheostatStyles}
        ${reactSlickStyles}
        ${richTextStyles}
      `}
    />
  );
};
