import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import {
  breakpoints,
  DynamicButton,
  DynamicButtonStatus,
  EmailField,
} from '../../../design-system';
import { validate } from '../../form/utils';
import { subscribeNewsletter } from '../../footer/utils';
import { useDispatch } from 'react-redux';
import { closeModalNew } from '../actions';
import { pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { durations } from '../constants';

const StyledNewsLetterSubscriptionContainer = styled.div`
  margin: 16px;
  max-width: 80vw;

  @media (min-width: ${breakpoints.S}px) {
    margin: 32px;
  }

  .subscription-modal-header {
    font-family: Ogg-text;
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;

    @media (min-width: ${breakpoints.S}px) {
      font-size: 2.8rem;
    }
  }

  .subscription-modal-text {
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;

    @media (min-width: ${breakpoints.S}px) {
      font-size: 2rem;
    }
  }

  .subscription-modal-input {
    font-size: 1.6rem;
  }

  .subscription-modal-form {
    margin: auto;
    max-width: 360px;
  }

  #subscription-modal-button {
    margin-top: 16px;
  }
`;

export const NewsLetterSubscription: FC = () => {
  const dispatch = useDispatch();
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const [value, setValue] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [btnStatus, setBtnStatus] = useState(DynamicButtonStatus.Default);

  const validateEmail = (value: string): string => validate({ value, key: 'email' });

  const handleChange = (value: string) => {
    setValue(value);
    const err = validateEmail(value);
    if (!err) {
      setErrMsg('');
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg(validateEmail(value));
    if (!validateEmail(value)) {
      setBtnStatus(DynamicButtonStatus.Loading);
      const isSubscriptionSuccess = await subscribeNewsletter({
        mail: value,
        origin: 'subscription-modal',
      });
      if (isSubscriptionSuccess) {
        pushToGTM(Events.newsletterCompletion, { type: 'popup' });
        setBtnStatus(DynamicButtonStatus.Success);
        timeoutId.current = setTimeout(() => dispatch(closeModalNew()), durations.UNDO_DELAY);
      } else {
        setBtnStatus(DynamicButtonStatus.Error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <StyledNewsLetterSubscriptionContainer>
      <p className="subscription-modal-header">Inscrivez-vous à la newsletter</p>
      <p className="subscription-modal-text">
        Votre rendez-vous bons plans & nouveautés Darjeeling !
        <br />
        On vous offre -10% sur votre première commande.
      </p>
      <form className="subscription-modal-form" onSubmit={handleSubmit} noValidate>
        <EmailField
          onChange={(value) => handleChange(value)}
          value={value}
          errMsg={errMsg}
          label="Votre email"
          id="subscription-modal-input"
          autoComplete="email"
          autoFocus
        />
        <DynamicButton
          type="submit"
          id="subscription-modal-button"
          disabled={Boolean(errMsg)}
          feedback={btnStatus}
          data={['VALIDER', 'INSCRIPTION VALIDÉE', 'UNE ERREUR EST SURVENUE']}
        />
      </form>
    </StyledNewsLetterSubscriptionContainer>
  );
};
