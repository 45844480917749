export enum CmsCustomTypes {
  cart = 'cart',
  ecart = 'ecart',
  catalog = 'catalog',
  product = 'product',
  banner = 'banner',
  footer = 'footer',
  ordersuccess = 'ordersuccess',
  contact = 'contact',
  generic = 'generic',
  homepage = 'homepage',
  menu = 'menu',
  storelocator = 'storelocator',
  notfound = 'notfound',
  promotion = 'promotion',
  offers = 'offers',
  grid = 'grid',
  account = 'account',
  order = 'order',
  invoice = 'invoice',
  emptycart = 'emptycart',
  search = 'search',
  delivery = 'delivery',
  usp = 'usp',
  payment = 'payment',
  affiliation = 'affiliation',
  bestsellers = 'bestsellers',
  ourbrand = 'ourbrand',
}

export type CmsText = {
  type: string;
  text: string;
  spans: any[];
}[];

export type CmsMediaLink = {
  height: string;
  width: string;
  id: string;
  kind: string;
  link_type: string;
  name: string;
  size: string;
  url: string;
};

export type CmsImage = {
  url: string;
  dimensions?: {
    width: number;
    height: number;
  };
  alt?: string | null;
  copyright?: string | null;
  desktop?: {
    url: string;
    dimensions?: {
      width: number;
      height: number;
    };
    alt?: string | null;
    copyright?: string | null;
  };
  mobile?: {
    url: string;
    dimensions?: {
      width: number;
      height: number;
    };
    alt?: string | null;
    copyright?: string | null;
  };
  normal?: {
    url: string;
  };
  retina?: {
    url: string;
  };
  scaled?: {
    url: string;
  };
};

export type CmsEmbed = {
  embed_url: string;
  html: string;
  height: number;
  width: number;
};

export type CmsLink = {
  url?: string;
  target?: string;
};

export type CmsFaqItem = {
  cart_faq_answer: CmsText;
  cart_faq_question: string;
};

export type CmsFaqCart = {
  id: string;
  items: CmsFaqItem[];
  primary: {
    cart_faq_background_color: string;
    cart_faq_title: string;
    cart_faq_title_color: string;
    cart_faq_link_text: string;
    cart_faq_link_color: string;
    cart_faq_divider_color: string;
    cart_faq_answer_color: string;
    cart_faq_question_color: string;
  };
  slice_label: string | null;
  slice_type: string;
};

export type CmsCart = {
  activate_banner: boolean;
  title: string;
  block: string;
  link: string;
  text_color?: string;
  background_color?: string;
  reassurance: {
    image: CmsImage;
    icon: string;
    text: string;
  }[];
  reassurances?: {
    reassurance_icon?: CmsImage;
  }[];
  shipping_promo_amount: number;
  pickup_shipping_promo_amount: number;
  shipping_promo_message: string;
  shipping_promo_message_remaining: CmsText;
  shipping_promo_message_free_delivery: CmsText;
  shipping_promo_message_pick_up: CmsText;
  shipping_promo_threshold_message: string;
  shipping_promo_label: string;
  gift_background_color?: string;
  gift_text_color?: string;
  gift_image?: CmsImage;
  cart_recommendation_title: string;
  body: CmsFaqCart[];
  cart_faq_is_active_desktop: boolean;
  cart_faq_is_active_mobile: boolean;
};

export type CmsDefaultCrossSell = {
  productref_default_cs: string;
  colorref_default_cs: string;
};

export type CmsECart = {
  reservation_text_modal: CmsText;
  reservation_text_line_one: string;
  reservation_text_line_two: string;
  reservation_text_line_three: string;
  standards: {
    icon: string;
    standard: string;
  }[];
  default_cross_sell: CmsDefaultCrossSell[];
};

export type CmsOrderSuccess = {
  image: CmsImage;
  title: CmsText;
  block: CmsText;
  cta: string;
  link: string;
};

export type CmsCatalogBanner = {
  primary?: {
    banner_background_color?: string;
    banner_row?: number;
    banner_row_mobile?: number;
    banner_text_color?: string;
    banner_type?: 'highlighted';
    is_banner_active?: boolean;
    is_banner_hidden_on_mobile?: boolean;
  };
  banner_type?: 'full column' | 'half column' | 'one tile' | 'highlighted';
  banner_column?: '1' | '2' | '3' | '4';
  banner_column_position?: 'left' | 'right';
  banner_text_position?: 'left' | 'center' | 'right';
  is_banner_active?: boolean;
  is_banner_hidden_on_mobile?: boolean;
  banner_row?: number;
  banner_row_mobile?: number;
  banner_product_ref?: string;
  banner_color_ref?: string;
  banner_video?: string;
  banner_gif?: CmsMediaLink;
  banner_link?: string;
  banner_promotion_end_date_time?: string;
  banner_text_color?: string;
  banner_text?: string;
  banner_image?: CmsImage;
  banner_image_mobile?: CmsImage;
  items?: CmsCatalogHighlightItem[];
};

export type CmsBannerImage = {
  url: string;
  dimensions?: {
    width: number;
    height: number;
  };
  full: {
    url: string;
    dimensions?: {
      width: number;
      height: number;
    };
  };
  half: {
    url: string;
    dimensions?: {
      width: number;
      height: number;
    };
  };
};

export type CmsBottomBanner = {
  image?: CmsBannerImage;
  image_mobile?: CmsImage;
  image_position: 'full' | 'left' | 'right';
  justification: 'left' | 'center' | 'right';
  alignment: 'left' | 'center' | 'right';
  text_color?: string;
  text?: CmsText;
  text_right?: CmsText;
  text_up?: CmsText;
  background_color?: string;
  link?: string;
  video?: string;
  is_hidden_on_mobile: boolean;
  is_active: boolean;
};

export type CmsCatalogFaqItem = {
  catalog_faq_answer_text: CmsText;
  catalog_faq_question_text: string;
};

export type CmsFaqCatalog = {
  id: string;
  items: CmsCatalogFaqItem[];
  primary: {
    catalog_faq_background: string;
    catalog_faq_divider_color: string;
    catalog_faq_question_color: string;
    catalog_faq_question_font_family: string;
    catalog_faq_answer_color: string;
    catalog_faq_answer_font_family: string;
    catalog_faq_title: string;
    catalog_faq_title_font_family: string;
    catalog_faq_title_font_size_desktop: string;
    catalog_faq_title_font_size_mobile: string;
    catalog_faq_title_color: string;
  };
  slice_label: string | null;
  slice_type: 'catalogue_faq';
};

export type CmsCatalogTestimonialItem = {
  author?: string;
  text_testimonial?: string;
  video_testimonial?: string;
  image_testimonial?: CmsImage;
};

export type CmsCatalogTestimonial = {
  id: string;
  items: CmsCatalogTestimonialItem[];
  primary: {
    catalog_testimonial_description: CmsText;
    catalog_testimonial_title: string;
  };
  slice_label: string | null;
  slice_type: 'catalog_testimonial';
};

export type CmsSliderItem = {
  slider_with_number_image?: CmsImage;
  slider_with_number_link?: {
    link_type: string;
    url: string;
  };
  slider_with_number_text?: string;
  slider_with_number_with_active_number_on_image?: boolean;
};

export type CmsSlider = {
  id: string;
  items: CmsSliderItem[];
  primary: {};
  slice_label: string | null;
  slice_type: 'slider_with_number';
};

export type CmsCatalogHighlightItem = {
  banner_image?: CmsImage;
  banner_product_ref?: string;
  banner_link?: string;
};
export type CmsCatalogHighlight = {
  id: string;
  items: CmsCatalogHighlightItem[];
  primary: {
    banner_type?: 'highlighted';
    banner_row?: number;
    banner_row_mobile?: number;
    title1?: CmsText;
    subtitle?: CmsText;
    banner_text_color?: string;
    banner_background_color?: string;
    is_banner_active: boolean;
    is_banner_hidden_on_mobile?: boolean;
    banner_column?: '1' | '2' | '3' | '4';
    banner_column_position?: 'left' | 'right';
  };
  slice_label?: string;
  slice_type: 'highlight';
};

export type CmsCatalog = {
  seotitle?: string;
  seodescription?: string;
  title?: string;
  description?: string;
  enable_shortcut_filters?: boolean;
  image?: CmsBannerImage;
  image_mobile?: CmsImage;
  image_position: 'full' | 'left' | 'right';
  justification: 'left' | 'center' | 'right';
  alignment: 'left' | 'center' | 'right';
  text_color?: string;
  text?: CmsText;
  text_right?: CmsText;
  text_up?: CmsText;
  cta_text?: string;
  promotion_banner_text?: string;
  promotion_end_date_time?: string;
  background_color?: string;
  video?: string;
  is_chevron_hidden: boolean;
  is_active: boolean;
  hide_filters: boolean;
  banners?: CmsCatalogBanner[];
  bottom_banners?: CmsBottomBanner[];
  promo_banner_type?: string;
  promo_banner_image?: {
    url: string;
  };
  promo_banner_image_mobile?: {
    url: string;
  };
  promotion_end_of_time?: string;
  title_promo_banner?: CmsText;
  subtitle_promo_banner?: CmsText;
  secondary_title_promo_banner?: CmsText;
  secondary_subtitle_promo_banner?: CmsText;
  terms_conditions_text?: string;
  background_color_promo_banner?: string;
  text_color_promo_banner?: string;
  terms_and_conditions_color?: string;
  promo_banner_is_active?: boolean;
  title_font_size_desktop?: string;
  title_font_size_mobile?: string;
  title_font_family?: string;
  subtitle_font_size_desktop?: string;
  subtitle_font_size_mobile?: string;
  subtitle_font_family?: string;
  secondary_title_font_size_desktop?: string;
  secondary_title_font_size_mobile?: string;
  secondary_title_font_family?: string;
  secondary_subtitle_font_size_desktop?: string;
  secondary_subtitle_font_size_mobile?: string;
  secondary_subtitle_font_family?: string;
  terms_conditions_font_size_desktop?: string;
  terms_conditions_font_size_mobile?: string;
  terms_conditions_font_family?: string;
  position_is_sticky_mobile?: boolean;
  description_banner_is_active_on_desktop?: boolean;
  description_banner_is_active_on_mobile?: boolean;
  description_banner_title?: string;
  description_banner_title_font_family?: string;
  description_banner_title_color?: string;
  description_banner_title_font_size_desktop?: string;
  description_banner_title_font_size_mobile?: string;
  description_banner_subtitle?: string;
  description_banner_subtitle_font_family?: string;
  description_banner_subtitle_color?: string;
  description_banner_subtitle_font_size_desktop?: string;
  description_banner_subtitle_font_size_mobile?: string;
  description_banner_content?: CmsText;
  description_banner_content_color?: string;
  description_banner_faq_link_text?: CmsText;
  description_banner_faq_link_text_color?: string;
  description_banner_link_target?: string;
  description_banner_image_desktop?: CmsImage;
  description_banner_image_mobile?: CmsImage;
  description_banner_background_color?: string;
  catalog_faq_is_active_desktop?: boolean;
  catalog_faq_is_active_mobile?: boolean;
  catalog_testimonial_is_active_desktop?: boolean;
  catalog_testimonial_is_active_mobile?: boolean;
  catalog_usp_is_active_desktop?: boolean;
  catalog_usp_is_active_mobile?: boolean;
  slider_with_number_is_active_desktop?: boolean;
  slider_with_number_is_active_mobile?: boolean;
  slider_with_number_text_is_active?: boolean;
  slider_with_number_is_sticky_mobile?: boolean;
  body?: CmsFaqCatalog[];
  body1?: CmsCatalogTestimonial[];
  body2?: CmsBannerWithPictos[];
  body3?: CmsCatalogHighlight[];
  body4?: CmsSlider[];
};

export type ColorBlock = {
  color_label: string;
  improved_color_name: string;
  color_image: CmsImage;
  first_picture: CmsImage;
  first_picture_height: number;
  video_1: string;
  quickadd_product_1: string;
  product_1_horizontal_position: number;
  product_1_vertical_position: number;
  quickadd_product_2: string;
  product_2_horizontal_position: number;
  product_2_vertical_position: number;
  second_picture: CmsImage;
  second_picture_height: number;
  video_2: string;
  quickadd_product_3: string;
  product_3_horizontal_position: number;
  product_3_vertical_position: number;
  quickadd_product_4: string;
  product_4_horizontal_position: number;
  product_4_vertical_position: number;
  text_title: string;
  text_paragraph: string;
  text_first_bullet_image: CmsImage;
  text_first_bullet_subtitle: string;
  text_first_bullet_text: string;
  text_second_bullet_image: CmsImage;
  text_second_bullet_subtitle: string;
  text_second_bullet_text: string;
};

export type CmsBannerWithPictos = {
  id?: string;
  items?: {
    banner_with_pictos_icon_name?: string;
    banner_with_picto_icon_text?: CmsText;
  }[];
  primary?: {
    banner_with_pictos_icon_background_color?: string;
    banner_with_pictos_is_active_desktop?: boolean;
    banner_with_pictos_is_active_mobile?: boolean;
  };
  slice_label?: string;
  slice_type?: string;
};

export type AffiliateTestimonial = {
  image_testimonial: CmsImage;
  text_testimonial: string | null;
  video_testimonial: string | null;
  author: string | null;
  gender_testimonial: string | null;
};

export type BodyImagesEl = {
  id: string;
  slice_type: 'images_with_animation';
  items: { image_animated: CmsImage }[];
};

export type BodyAffiliateTestimonialEl = {
  id: string;
  slice_type: 'testimonials';
  items: AffiliateTestimonial[];
};

export type CmsAffiliate = {
  first_image: {
    url: string;
  };
  first_video: string;
  second_video_mobile: string;
  second_video_desktop: string;
  pdf_affiliation: {
    url: string;
  };
  body: (BodyImagesEl | BodyAffiliateTestimonialEl)[];
  seo_description: CmsText;
  meta_title: string;
  meta_description: string;
};

export type ProductUsp = {
  usp_title: string;
  usp_icon: string;
  body: CmsText;
};

export type CmsProduct = {
  usp: ProductUsp[];
  thumbnail_image?: {
    thumbnail?: {
      url: string;
    };
  };
  price_bras?: number;
  price_panties?: number;
  csr_block_title?: string;
  title_rejects_microfibers?: string;
  product_rejects_microfibers_is_true?: string;
  product_rejects_microfibers_is_false?: string;
  title_recycled_materials?: string;
  recycled_material_is_null?: string;
  recycled_materials_intermediate_value?: string;
  recycled_materials_is_full?: string;
  title_recyclability?: string;
  product_recyclability_is_true?: string;
  product_recyclability_is_false?: string;
  title_toxicity?: string;
  product_toxicity_is_true?: string;
  product_toxicity_is_false?: string;
  title_traceability?: string;
  title_table_first_line?: string;
  title_table_second_line?: string;
  title_table_third_line?: string;
  hide_csr_block?: boolean;
  cross_sell_title: string;
  up_sell_title: string;
  body?: CmsHomepageCommitmentsBannerSlice[];
};

export type CmsBanner = {
  body: string;
  textcolor: string;
  backgroundcolor: string;
  link: string;
  promotion_end_date_time?: string;
};

export type CmsFooter = {
  body: {
    primary: {
      title: CmsText;
    };
    items: {
      color: string;
      text: CmsText;
    }[];
  }[];
  icon_news_letter?: string;
  news_title?: string;
  news_body_text?: CmsText;
  news_input_text?: string;
  news_background_color?: string;
  payment_methods_image?: CmsImage;
  payment_methods?: {
    payment_method?: CmsImage;
  }[];
  contact?: {
    icon?: string;
    link?: {
      url?: string;
    };
  }[];
};

export type CmsGeneric = {
  type: string;
  title: CmsText;
  subtitle: CmsText;
  message: CmsText;
  image: string;
  body: {
    primary: {
      title: CmsText;
    };
    items: {
      title: CmsText;
      content: CmsText;
      note: CmsText;
    }[];
  }[];
};

export type CmsContact = {
  description: CmsText;
  subtitle: CmsText;
  link: {
    icon: string;
    text: CmsText;
    note: CmsText;
  }[];
  info: CmsText;
  body: {
    primary: {
      subject: string;
    };
    items: {
      option: string;
    }[];
  }[];
};

export type CmsLegalCGV = {
  content: CmsText;
};

export type CmsCtaType =
  | 'white link'
  | 'black link'
  | 'white button'
  | 'black button'
  | 'ghost button'
  | 'color button';

export type CmsHomepagePosition =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16';

export type CmsHomepageBanner = {
  image?: CmsBannerImage;
  image_mobile?: CmsImage;
  position: CmsHomepagePosition;
  type: 'slider' | 'full' | 'half';
  justification: 'left' | 'center' | 'right';
  alignment: 'left' | 'center' | 'right';
  image_position: 'full' | 'left' | 'right';
  title_alignment: 'left' | 'center';
  cta_type: CmsCtaType;
  cta_type_mobile: CmsCtaType;
  conditions_text_color: string;
  text?: CmsText;
  text_right?: CmsText;
  text_up?: CmsText;
  cta_text?: string;
  cta_link?: string;
  conditions?: string;
  text_position_for_double_image_in_type_full:
    | 'text centered on left image'
    | 'text centered on right image'
    | null;
  text_color?: string;
  text_color_mobile?: string;
  background_color?: string;
  background_color_mobile?: string;
  video?: string;
  video_mobile?: string;
  promotion_end_date_time?: string;
  cta_color?: string;
  cta_color_mobile?: string;
  cta_font_color?: string;
  cta_font_color_mobile?: string;
  title?: string;
  subtitle?: string;
  is_active: boolean;
  title_size_desktop?: string;
  title_size_mobile?: string;
  subtitle_size_desktop?: string;
  subtitle_size_mobile?: string;
  font_family_title?: string;
  font_family_subtitle?: string;
};

export type CmsUspContent = {
  title?: string;
  subtitle?: string;
  link?: string;
  icon?: string;
  points?: CmsText;
};

export type CmsHomepageCategory = {
  cta_color?: string;
  cta_text?: string;
  cta_type: 'Bouton' | 'Link';
  image?: CmsImage;
  image_mobile?: CmsImage;
  link?: string;
  text_color?: string;
};

export type CmsHomepageTitle = {
  alignment: 'left' | 'center';
  position: CmsHomepagePosition;
  text?: string;
  font_family?: string;
  font_size_mobile?: number;
  font_size_desktop?: number;
};

export type CmsHomepageSpacing = {
  position: CmsHomepagePosition;
  height_mobile?: number;
  height_desktop?: number;
  background_color?: string;
  background_color_mobile?: string;
};

export type CmsHomepageRecommendationsTab = {
  id: string;
  items: { name?: string; t2s_id?: string }[];
  primary: { position: CmsHomepagePosition; title: string; is_active: boolean };
  slice_label?: string;
  slice_type: 'reco_tabs';
};

export type CmsHomepageThinBannerSlice = {
  id: string;
  items: object[];
  primary: CmsHomepageThinBanner;
  slice_label?: string;
  slice_type: 'thin_banner';
};

export type CmsHomepagePromoCategoriesByButtonSlice = {
  id: string;
  items: CmsHomepagePromoCategoriesByButtonItems[];
  primary: CmsHomepagePromoCategoriesByButton;
  slice_label?: string;
  slice_type: 'promo_categories_by_button';
};

export type CmsHomepageCollectionBannerSlice = {
  id: string;
  items: object[];
  primary: CmsHomepageCollectionBanner;
  slice_label?: string;
  slice_type: 'collection_banner';
};

export type CmsHomepageCommitmentsBannerSlice = {
  id?: string;
  primary: {
    position?: CmsHomepagePosition;
    commitments_banner_title: string;
  };
  items: {
    icon: string;
    description: string;
    link: string;
    image1?: CmsImage;
  }[];
  slice_label?: string;
  slice_type: 'commitments_banner';
};

export type CmsHomepageBannerSlice =
  | CmsHomepageThinBannerSlice
  | CmsHomepagePromoCategoriesByButtonSlice
  | CmsHomepageCollectionBannerSlice
  | CmsHomepageCommitmentsBannerSlice;

export type CmsHomepageCollectionBanner = {
  position?: CmsHomepagePosition;
  title?: CmsText;
  title_mobile?: CmsText;
  description?: CmsText;
  description_mobile?: CmsText;
  image?: CmsImage;
  image_mobile?: CmsImage;
  cta_name?: string;
  cta_type?: CmsCtaType;
  cta_link?: string;
  cta_background_color?: string;
  cta_font_color?: string;
  product_ref_1?: string;
  custom_image_1?: CmsImage;
  product_ref_2?: string;
  custom_image_2?: CmsImage;
  product_ref_3?: string;
  custom_image_3?: CmsImage;
  product_ref_4?: string;
  custom_image_4?: CmsImage;
};
export type CmsHomepageThinBanner = {
  position: CmsHomepagePosition;
  banner_text_color?: string;
  title?: CmsText;
  title_mobile?: CmsText;
  image_title?: CmsText;
  image_title_mobile?: CmsText;
  promo_description?: CmsText;
  promo_description_mobile?: CmsText;
  promo?: CmsText;
  promo_mobile?: CmsText;
  cta_type?: CmsCtaType;
  cta_text_color?: string;
  cta_background_color?: string;
  cta_name?: string;
  cta_link?: string;
  background_color?: string;
  image?: CmsImage;
  image_mobile?: CmsImage;
};

export type CmsHomepagePromoCategoriesByButton = {
  position?: CmsHomepagePosition;
  title?: CmsText;
  title_mobile?: CmsText;
  title_color?: string;
  description?: CmsText;
  description_mobile?: CmsText;
  description_color?: string;
  background_image?: CmsImage;
  background_image_mobile?: CmsImage;
};

export type CmsHomepagePromoCategoriesByButtonItems = {
  cta_name?: string;
  cta_type?: CmsCtaType;
  link?: string;
  cta_background_color?: string;
  cta_font_color?: string;
};

export type CmsHomepage = {
  seotitle: string;
  seodescription: string;
  banners: CmsHomepageBanner[];
  categories: CmsHomepageCategory[];
  titles: CmsHomepageTitle[];
  spaces: CmsHomepageSpacing[];
  categories_block_is_active_desktop: boolean;
  categories_block_is_active_mobile: boolean;
  category_position: CmsHomepagePosition;
  categories_background_color_mobile?: string;
  categories_block_title_mobile?: CmsText;
  categories_block_title_desktop?: CmsText;
  categories_title_alignment_desktop?: 'left' | 'center' | 'right';
  categories_title_alignment_mobile?: 'left' | 'center' | 'right';
  recommendations_block_is_active: boolean;
  recommendations_position: CmsHomepagePosition;
  recommendations_t2s_title: string;
  body: CmsHomepageBannerSlice[];
  body1: CmsBrandBanner[];
  body2: CmsCategories[];
  recommendations_tabs: CmsHomepageRecommendationsTab[];
};

export type CmsMenuItem = {
  type: string;
  picto_for_mobile?: string;
  name: string;
  link?: string;
  image: {
    url: string;
  } | null;
};

export type CmsMenu = {
  items: CmsMenuItem[];
};

export type StoreLocatorService = {
  icon: string;
  service_title: string;
  service_content: CmsText;
};

export type StorePromotion = {
  promotion_image: CmsImage;
  promotion_title: string;
  promotion_subtitle: string;
};

export type StoreAssistances = {
  assistance_image: CmsImage;
  assistance_title: string;
  assistance_text: CmsText;
};

export type CmsStoreLocator = {
  title: string;
  body: string;
  placeholder: string;
  services: StoreLocatorService[];
  store_image: CmsImage;
  promotions: StorePromotion[];
  promotions_title: string;
  promotions_subtitle: string;
  assistances: StoreAssistances[];
};

export type CmsNotFound = {
  title: string;
  body: CmsText;
  subtitle: string;
  items: {
    text: string;
    link: string;
    image: CmsImage;
  }[];
};

export type BodyCmsPromotion = {
  id: string;
  slice_type: 'images';
  items: { image_label: CmsImage }[];
};

export type CmsPromotion = {
  body?: BodyCmsPromotion;
  label_text_color?: string;
  label_background_color?: string;
  discount_percentages?: {
    text_color?: string;
    background_color?: string;
  }[];
};

export type CmsOfferItem = {
  name?: string;
  text_color?: string;
  background_color?: string;
  coupon_code?: string;
  valid_from?: string;
  valid_to?: string;
  tooltip_message?: string;
  tooltip_message_expired?: string;
  cta_link?: CmsLink;
  conditions?: CmsText;
  exclusions?: CmsText;
  image?: {
    url?: string;
    desktop?: {
      url?: string;
    };
    mobile?: {
      url?: string;
    };
  };
};

export type CmsOffers = {
  items: CmsOfferItem[];
};

export type CmsRow = {
  type: string;
  column: 'full' | 'half' | 'one-third' | 'quarter';
  body?: CmsText;
  image?: CmsImage;
  image_mobile?: CmsImage;
  video?: string;
  is_video_wide_format?: boolean;
  ctalink?: string;
  ctatext?: string;
  ctatype?: 'dark' | 'light' | 'subtle' | 'link';
  cta_type_mobile?: 'dark' | 'light' | 'subtle' | 'link';
  cta_position?: 'on-image' | 'below';
  background_color?: string;
};

export type CmsGrid = {
  type: string;
  seotitle: string;
  seodescription: string;
  items: CmsRow[];
  notFound?: boolean;
};

export type CmsAccount = {
  body: CmsText;
};

export type CmsOrder = {
  body: CmsText;
};

export type CmsInvoice = {
  body: CmsText;
  image: CmsImage;
};

export type CmsEmptyCart = {
  title: string;
  recommendations: {
    text: string;
    link: string;
    image: CmsImage;
  }[];
};

export type CmsSearchLinks = {
  id: string;
  items: {
    search_link_name: string;
    search_link_url: string;
  }[];
  primary: {
    search_link_title: string;
  };
  slice_label: null;
  slice_type: 'search_links' | 'help_section';
};

export type CmsSearchMostWanted = {
  id: string;
  items: {
    most_wanted_product_ref: string;
  }[];
  primary: {
    most_wanted_title: string;
  };
  slice_label: null;
  slice_type: 'most_wanted';
};

export type CmsSearchBannerTiles = {
  id: string;
  items: {}[];
  primary: {
    banner_tile_image_one: CmsImage;
    banner_tile_image_one_link: string;
    banner_tile_image_one_text: string;
    banner_tile_image_one_text_height_position: number;
    banner_tile_image_two: CmsImage;
    banner_tile_image_two_link: string;
    banner_tile_image_two_text: string;
    banner_tile_image_two_text_height_position: number;
  };
  slice_label: null;
  slice_type: 'banner_tiles';
};

export type CmsSearch = {
  placeholder: string;
  body?: CmsSearchLinks[];
  body1?: CmsSearchLinks[];
  body2?: CmsSearchMostWanted[];
  body3?: CmsSearchBannerTiles[];
};

export type CmsDelivery = {
  hometext?: string;
  storetext?: string;
  pickuptext?: string;
  home_delivery_min_day?: number;
  home_delivery_max_day?: number;
  store_delivery_min_day?: number;
  store_delivery_max_day?: number;
  pickup_delivery_min_day?: number;
  pickup_delivery_max_day?: number;
};

export type CmsUsp = {
  usp_main_title?: string;
  usp: CmsUspContent[];
};

export type PaymentWarning = {
  warning_method_name?:
    | 'Carte cadeau Darjeeling'
    | 'Carte cadeau Illicado'
    | 'Google Pay'
    | 'PayPal'
    | 'Cartes de paiement';
  warning_title?: string;
  warning_method_type?: 'warning' | 'information' | 'success' | 'error';
  warning_description?: string;
  length: number;
};

export type CmsPayment = {
  tooltip_title?: string;
  tooltip_icon?: string;
  tooltip_reasons?: CmsText;
  warnings?: PaymentWarning[];
};

export type CmsTopTenDotColor = 'WHITE' | 'BLACK';

export type CmsTopTenItem = {
  top_ten_image?: CmsImage;
  top_ten_page_block_title?: string;
  top_ten_page_block_subtitle?: string;
  top_ten_page_first_bottom_product_ref?: string;
  top_ten_page_second_bottom_product_ref?: string;
  top_ten_page_third_bottom_product_ref?: string;
  top_ten_page_fourth_bottom_product_ref?: string;
  top_ten_page_testimonial_title?: string;
  top_ten_page_testimonial_text?: string;
  top_ten_page_testimonial_author?: string;
  top_ten_page_testimonial_link?: string;
  top_ten_page_first_product_ref?: string;
  top_ten_page_second_product_ref?: string;
  top_ten_dot_one_color?: CmsTopTenDotColor;
  top_ten_image_dot_one_horizontal_position?: number;
  top_ten_image_dot_one_vertical_position?: number;
  top_ten_dot_two_color?: CmsTopTenDotColor;
  top_ten_image_dot_two_horizontal_position?: number;
  top_ten_image_dot_two_vertical_position?: number;
  top_ten_dot_three_color?: CmsTopTenDotColor;
  top_ten_image_dot_three_horizontal_position?: number;
  top_ten_image_dot_three_vertical_position?: number;
  top_ten_product_ref_for_dot_one?: string;
  top_ten_product_ref_for_dot_two?: string;
  top_ten_product_ref_for_dot_three?: string;
  top_ten_label_block_icon_name?: string;
  top_ten_label_block_icon_color?: string;
  top_ten_label_block_icon_background_color?: string;
  top_ten_label_block_text?: string;
  top_ten_label_block_text_color?: string;
  top_ten_label_block_text_background_color?: string;
};

export type CmsToptenSlice = {
  id?: string;
  items?: CmsTopTenItem[];
  primary?: {
    top_ten_page_title?: string;
    top_ten_page_subtitle?: string;
  };
  slice_label?: string;
  slice_type?: 'bestsellers-block';
};

export type CmsBestSellers = {
  body?: CmsSlider[];
  body1?: CmsToptenSlice[];
  slider_with_number_is_active_desktop?: boolean;
  slider_with_number_is_active_mobile?: boolean;
  slider_with_number_text_is_active?: boolean;
  slider_with_number_is_sticky_mobile?: boolean;
  top_ten_bottom_seo_description?: CmsText;
  top_ten_meta_description?: string;
  top_ten_meta_title?: string;
  top_ten_bottom_description_alignment?: string;
};

export type CmsFontFamily = 'Ogg-text' | 'MaisonNeue';

export type CmsHtmlTag = 'p' | 'h2' | 'h1';

export type CmsOurBrandPosition =
  | 'Not activated'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20';

export type CmsBrandBanner = {
  id?: string;
  items?: {}[];
  primary?: {
    brand_banner_position_on_page: CmsOurBrandPosition;
    brand_banner_animation_active: boolean;
    brand_banner_margin_bottom: number;
    brand_banner_margin_bottom_small_screen: number;
    brand_banner_text_on_image_block_width: number;
    brand_banner_text_on_image_block_width_small_screen: number;
    brand_banner_image: CmsImage;
    brand_banner_image_mobile: CmsImage;
    brand_banner_container_link: string;
    brand_banner_container_link_aria_label: string;
    text_on_image: string;
    text_on_image_mobile: string;
    text_on_image_font_family: CmsFontFamily;
    text_on_image_font_size: number;
    text_on_image_font_size_small_screen: number;
    text_on_image_font_weight: number;
    text_on_image_color: string;
    side_block_position: 'left' | 'right';
    side_block_background_color: string;
    side_title_text: string;
    side_title_text_font_family: CmsFontFamily;
    side_title_text_font_size: number;
    side_title_text_font_size_small_screen: number;
    side_title_text_font_weight: number;
    side_title_text_color: string;
    side_text: CmsText;
    side_text_font_family: CmsFontFamily;
    side_text_font_size: number;
    side_text_font_size_small_screen: number;
    side_text_color: string;
  };
  slice_label?: null;
  slice_type?: 'brand_banner';
};

export type CmsCategories = {
  id?: string;
  items?: {
    cta_color?: string;
    cta_text?: string;
    cta_type: 'Bouton' | 'Link';
    image?: CmsImage;
    image_mobile?: CmsImage;
    link?: string;
    text_color?: string;
  }[];
  primary?: {
    categories_block_is_active_desktop: boolean;
    categories_block_is_active_mobile: boolean;
    category_position: CmsHomepagePosition;
    categories_block_title_desktop?: CmsText;
    categories_title_alignment_desktop?: 'left' | 'center' | 'right';
    categories_block_title_mobile?: CmsText;
    categories_title_alignment_mobile?: 'left' | 'center' | 'right';
    categories_background_color_mobile?: string;
  };
  slice_label?: string;
  slice_type?: 'categories';
};

export type CmsFullTitle = {
  id?: string;
  items?: {}[];
  primary?: {
    full_title_position_on_page: CmsOurBrandPosition;
    full_title_animation_active: boolean;
    full_title_margin_bottom: number;
    full_title_margin_bottom_small_screen: number;
    full_title_position_on_image: number;
    full_title_position_on_image_small_screen: number;
    full_title_font_family: CmsFontFamily;
    full_title_image: CmsImage;
    full_title_image_small_screen: CmsImage;
    full_title_text: string;
    full_title_text_color: string;
    full_title_html_tag: CmsHtmlTag;
    full_title_text_font_size: number;
    full_title_text_font_size_small_screen: number;
    full_title_text_font_weight: number;
  };
  slice_label?: null;
  slice_type?: 'full_title';
};

export type CmsTagLine = {
  id?: string;
  items?: {}[];
  primary?: {
    tag_line_position_on_page: CmsOurBrandPosition;
    tag_line_animation_active: boolean;
    tag_line_margin_bottom: number;
    tag_line_margin_bottom_small_screen: number;
    tag_line_text: string;
    tag_line_text_background_color: string;
    tag_line_text_color: string;
    tag_line_text_font_family: CmsFontFamily;
    tag_line_html_tag: CmsHtmlTag;
    tag_line_text_font_size: number;
    tag_line_text_font_size_small_screen: number;
    tag_line_text_font_weight: number;
  };
  slice_label?: null;
  slice_type?: 'tag_line';
};

export type CmsGalleryBanner = {
  id?: string;
  items?: {}[];
  primary?: {
    gallery_banner_position_on_page: CmsOurBrandPosition;
    gallery_banner_margin_bottom: number;
    gallery_banner_margin_bottom_small_screen: number;
    gallery_banner_title: string;
    gallery_banner_title_color: string;
    gallery_banner_title_font_family: CmsFontFamily;
    gallery_banner_title_font_size: number;
    gallery_banner_title_font_size_small_screen: number;
    gallery_banner_title_font_weight: number;
    gallery_banner_subtitle: CmsText;
    gallery_banner_subtitle_color: string;
    gallery_banner_subtitle_font_family: CmsFontFamily;
    gallery_banner_subtitle_font_size: number;
    gallery_banner_subtitle_font_size_small_screen: number;
    gallery_block_text: CmsText;
    gallery_block_text_color: string;
    gallery_block_text_font_family: CmsFontFamily;
    gallery_block_text_font_size: number;
    gallery_block_text_font_size_small_screen: number;
    gallery_block_title: string;
    gallery_block_title_color: string;
    gallery_block_title_font_family: CmsFontFamily;
    gallery_block_title_font_size: number;
    gallery_block_title_font_size_small_screen: number;
    gallery_block_title_font_weight: number;
    gallery_block_with_text_background_color: string;
    gallery_image_1: CmsImage;
    gallery_image_2: CmsImage;
    gallery_image_3: CmsImage;
    gallery_image_4: CmsImage;
    gallery_image_5: CmsImage;
  };
  slice_label?: null;
  slice_type?: 'gallery_banner';
};

export type CmsAnimatedPictures = {
  id?: string;
  items?: {
    animated_image: CmsImage;
    animated_pictures_text: CmsText;
    animated_pictures_title: string;
  }[];
  primary?: {
    animated_pictures_position_on_page: CmsOurBrandPosition;
    animated_pictures_margin_bottom: number;
    animated_pictures_margin_bottom_small_screen: number;
    animated_pictures_text_font_family: CmsFontFamily;
    animated_pictures_text_font_size: number;
    animated_pictures_text_font_size_small_screen: number;
    animated_pictures_title_font_family: CmsFontFamily;
    animated_pictures_title_font_size: number;
    animated_pictures_title_font_size_small_screen: number;
    animated_pictures_title_font_weight: number;
  };
  slice_label?: null;
  slice_type?: 'animated_picture';
};

export type CmsPicturesSlider = {
  id?: string;
  items?: {
    pictures_slider_image: CmsImage;
    pictures_slider_image_link: string;
  }[];
  primary?: {
    pictures_slider_position_on_page: CmsOurBrandPosition;
    pictures_slider_animation_active: boolean;
    pictures_slider_animation_speed: number;
    pictures_slider_margin_bottom: number;
    pictures_slider_margin_bottom_small_screen: number;
  };
  slice_label?: null;
  slice_type?: 'pictures_slider';
};

export type CmsCategorieBanner = {
  id?: string;
  items?: {
    image: CmsImage;
    image_mobile: CmsImage;
    link: string;
    text_color: string;
    cta_color: string;
    cta_text: string;
    cta_type: 'Bouton' | 'Link';
  }[];
  primary?: {
    categorie_banner_position_on_page: CmsOurBrandPosition;
    categorie_banner_margin_bottom: number;
    categorie_banner_margin_bottom_small_screen: number;
    categorie_banner_activation_desktop: boolean;
    categorie_banner_activation_mobile: boolean;
    categorie_banner_background_color_mobile: string;
    categorie_banner_title_alignment_desktop: 'left' | 'center' | 'right';
    categorie_banner_title_alignment_mobile: 'left' | 'center' | 'right';
    categorie_banner_title_desktop: CmsText;
    categorie_banner_title_mobile: CmsText;
  };
  slice_label?: null;
  slice_type?: 'categorie_banner';
};

export type CmsTabsCategorieBanner = {
  id?: string;
  items?: {
    tabs_categorie_image: CmsImage;
    tabs_categorie_gif: CmsMediaLink;
    tabs_categorie_video_link: string;
    tabs_categorie_section_text: string;
    tabs_categorie_text_description: CmsText;
    tabs_categorie_product_ref_one: string;
    tabs_categorie_product_ref_two: string;
  }[];
  primary?: {
    tabs_categorie_position_on_page: CmsOurBrandPosition;
    tabs_categorie_margin_bottom: number;
    tabs_categorie_margin_bottom_small_screen: number;
    tabs_categorie_title: string;
    tabs_categorie_title_color: string;
    tabs_categorie_title_font_family: CmsFontFamily;
    tabs_categorie_title_font_size: number;
    tabs_categorie_title_font_size_small_screen: number;
    tabs_categorie_title_font_weight: number;
  };
  slice_label?: null;
  slice_type?: 'tabs_categorie';
};

export type CmsFullSlider = {
  id?: string;
  items?: {
    full_slider_image: CmsImage;
    full_slider_text_under_media: CmsText;
    full_slider_video_link: string;
  }[];
  primary?: {
    full_slider_position_on_page: CmsOurBrandPosition;
    full_slider_margin_bottom: number;
    full_slider_margin_bottom_small_screen: number;
    full_slider_title: string;
    full_slider_html_tag: CmsHtmlTag;
    full_slider_title_font_family: CmsFontFamily;
    full_slider_title_font_size: number;
    full_slider_title_font_size_small_screen: number;
    full_slider_title_font_weight: number;
    full_slider_title_and_text_color: string;
    full_slider_background_color: string;
  };
  slice_label?: null;
  slice_type?: 'full_slider';
};

export type CmsOurBrand = {
  ourbrand_bottom_seo_description?: CmsText;
  ourbrand_bottom_seo_description_alignment?: 'left' | 'center' | 'right';
  ourbrand_meta_description?: string;
  ourbrand_meta_title?: string;
  body?: CmsBrandBanner[];
  body1?: CmsFullTitle[];
  body2?: CmsTagLine[];
  body3?: CmsGalleryBanner[];
  body4?: CmsAnimatedPictures[];
  body5?: CmsPicturesSlider[];
  body6?: CmsCategorieBanner[];
  body7?: CmsTabsCategorieBanner[];
  body8?: CmsFullSlider[];
};

export type State = {
  affiliation: CmsAffiliate;
  ourbrand: CmsOurBrand;
  cart: CmsCart;
  ecart: CmsECart;
  catalog?: CmsCatalog;
  product: CmsProduct;
  banner: CmsBanner;
  footer: CmsFooter;
  ordersuccess: CmsOrderSuccess;
  contact: CmsContact;
  generic: CmsGeneric;
  homepage: CmsHomepage;
  menu: CmsMenu;
  storelocator: CmsStoreLocator;
  notfound: CmsNotFound;
  promotion: CmsPromotion;
  offers: CmsOffers;
  grid: CmsGrid;
  account: CmsAccount;
  order: CmsOrder;
  invoice: CmsInvoice;
  emptycart: CmsEmptyCart;
  search: CmsSearch;
  bestsellers: CmsBestSellers;
  delivery: CmsDelivery;
  usp: CmsUsp;
  payment: CmsPayment;
  masterList: string[];
  isLoading: boolean;
  uid: string;
};
