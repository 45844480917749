import React, { useEffect, useRef, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Icon, Box, Field, DynamicButtonStatus, DynamicButton } from '../../../design-system';
import { CmsFooter } from '../../cms/types';
import { Forms } from '../../form/types';
import { setFormValues, setFormValidation, setCtaState } from '../../form/actions';
import { validate } from '../../form/utils';
import { RootState } from '../../../store/rootReducer';
import { subscribeNewsletter } from '../utils';
import { CTA_SUBSCRIBE, LBL_EMAIL } from '../locale';
import { pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';

type Props = {
  cmsContent: CmsFooter;
};
const StyledBoxForRichText = styled(Box)(
  css({
    p: {
      marginBlockStart: 0,
      marginBlockEnd: ['8px', '4px'],
      fontSize: 'm',
      lineHeight: '110%',
      width: ['247px', 'auto'],
    },
    strong: {
      fontWeight: 700,
    },
  })
);

export const Newsletter = ({ cmsContent }: Props) => {
  const icon = cmsContent?.icon_news_letter;
  const title = cmsContent?.news_title;
  const inputText = cmsContent?.news_input_text;
  const bodyRichText = cmsContent?.news_body_text;
  const backgroundColor = cmsContent?.news_background_color ?? 'BACKGROUND';
  const newsLetterRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form.footerNewsletter);

  useEffect(() => {
    return () => {
      dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: '' } }));
      dispatch(setFormValidation({ form: Forms.footerNewsletter, values: { email: '' } }));
      dispatch(
        setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Default })
      );
    };
  }, []);

  const validateEmail = (value: string): string =>
    validate({
      value: String(value),
      key: 'email',
      isRequired: true,
    });

  const onEmailChange = (value: string) => {
    if (form.validation.email) {
      dispatch(
        setFormValidation({
          form: Forms.footerNewsletter,
          values: { email: validateEmail(value) },
        })
      );
    }

    dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: value } }));
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errMsg = validateEmail(form.values.email);
    dispatch(
      setFormValidation({
        form: Forms.footerNewsletter,
        values: { email: errMsg },
      })
    );

    if (!errMsg) {
      dispatch(
        setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Loading })
      );
      const isOk = await subscribeNewsletter({ mail: form.values.email, origin: 'footer' });
      if (isOk) {
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Success })
        );
        pushToGTM(Events.newsletterCompletion, { type: 'footer' });

        await new Promise((resolve) => setTimeout(resolve, 3000));
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Default })
        );
        dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: '' } }));
      } else {
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Error })
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(
          setCtaState({
            form: Forms.footerNewsletter,
            ctaState: DynamicButtonStatus.Default,
          })
        );
      }
    }
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <Box
        ref={newsLetterRef}
        p={['40px 16px 40px 16px', '40px 0px 40px 0px']}
        bg={backgroundColor}
        display="flex"
        flexDirection="column"
        width="100%"
        minHeight={['385px', '297px', '304px']}
        alignItems="center"
        id="box-newsletter"
      >
        {icon && <Icon name={icon} size={30} />}
        {title && (
          <Box
            mb={['m', 's']}
            mt="12px"
            fontSize={['30px', '24px', '30px']}
            fontWeight={700}
            fontFamily="ogg-text"
            textAlign="center"
            lineHeight="110%"
          >
            {title}
          </Box>
        )}
        {bodyRichText?.[0]?.text && (
          <StyledBoxForRichText mb="l" color="GREY" width={['auto', '451px']} textAlign="center">
            <RichText render={bodyRichText} />
          </StyledBoxForRichText>
        )}
        <Box
          display="flex"
          width={['100%', '451px']}
          pb={form.validation.email !== '' ? 'm' : 'xs'}
        >
          <Box height="50px" border="1px solid #E6E6E6" width={['60%', '308px']}>
            <Field
              id="input-newsletter-email"
              value={form.values.email}
              onChange={onEmailChange}
              errMsg={form.validation.email}
              placeholder={LBL_EMAIL}
              type="email"
              autoComplete="email"
              isFooterInput
            />
          </Box>
          <Box height="50px" bg="black" py="2px" width={['40%', '143px']} mt="1px">
            <DynamicButton
              id="btn-newsletter-send"
              type="submit"
              data={CTA_SUBSCRIBE}
              feedback={form.ctaState}
              fontWeight="700"
            />
          </Box>
        </Box>
        {inputText && (
          <Box fontSize="12px" lineHeight="110%" mt="s" width={['100%', '451px']}>
            {inputText}
          </Box>
        )}
      </Box>
    </form>
  );
};
